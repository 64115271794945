import OrderModel from '@/models/OrderModel';

/** I'm using OrderModel class here to bring different type of data to same structure.
 *
 * This card will be used to display 3 different type of orders:
 * - Sponsorships orders,
 * - Signage orders,
 * - Free Standing orders.
 *
 * With initializeOrder method I'll get the same order object,
 * in all 3 cases. It allows me to avoid bunch of v-if statements in html template,
 * that makes component more readable.
 */

export const InitializeOrderMixin = {

	methods: {
		initializeOrder(data) {

			let order;

			if (data.media_id == 6) {

				order = new OrderModel(
					'sponsorship',
					data.sponsorships_id,
					data.accounts_name,
					data.artwork_id,
					data.artwork_original_name,
					data.artwork_url_thumbnail,
					data.artwork_url_download,
					data.artwork_history_notes,
					data.adspaces_id,
					data.adspaces_description,
					data.installation_group_id,
					data.installation_group_name,
					data.end_market_disposition,
					data.sponsorships_install_notes,
					data.material_id,
					data.material_name,
					data.requirement_width,
					data.requirement_height,
					data.requirement_units,
					data.sponsorship_status_id,
					data.sponsorship_status_stage,
					data.sponsorship_status_name,
					data.account_executives_first + ' ' +
					data.account_executives_last,
					data.account_executives_email,
					'',
					'',
					'',
					'',
					'',
					data.sponsorships_quantity,
					data.end_market_disposition,
					data.size,
					data.install_by,
					data.leave_until,
					data.remove_by,
					data.adspaces_location,
					data.qr_code_image_url,
					data.original_url,
					data.location_code,
					data.adspaces_location_name,
					data.adspaces_print_notes
				);
			} else if (!data.media_id) {
				order = new OrderModel(
					'signage',
					data.id,
					'IMC',
					data.artwork_id,
					data.proof_original_name,
					data.proof_thumbnail_url ?
						data.proof_thumbnail_url :
						data.thumbnail_url,
					data.proof_download_url,
					data.proof_notes,
					data.signage_id,
					data.signage_description,
					data.installation_group_id,
					data.installation_group_name,
					data.end_market_disposition,
					{
						'notes': data.notes,
						'reference': data.reference_field,
						'upload': data.upload_description,
					},
					data.material_id,
					data.material_name,
					data.signage_width,
					data.signage_height,
					data.signage_units,
					data.status_id,
					data.status_stage,
					data.status_name,
					'',
					'',
					data.front_copy,
					data.back_copy,
					data.reference_field,
					data.support_method,
					data.description,
					data.quantity,
					data.end_market_disposition,
					data.size,
					data.install_by,
					data.leave_until,
					data.remove_by,
					data.signage_location_id,
					data.qr_code_image_url,
					data.upload_original_url,
					data.location_code,
					data.location_name
				);
			} else if (data.media_id == 9) {
				order = new OrderModel(
					'free-standing',
					data.id,
					'IMC',
					data.artwork_id,
					data.proof_original_name,
					data.proof_thumbnail_url,
					data.proof_download_url,
					data.artwork_history_notes,
					data.adspaces_id,
					data.adspaces_description,
					data.installation_group_id,
					data.installation_group_name,
					data.end_market_disposition,
					data.notes,
					data.material_id,
					data.material_name,
					data.width,
					data.height,
					data.adspace_units,
					data.sponsorship_status_id,
					data.sponsorship_status_stage,
					data.sponsorship_status_name,
					data.account_executives_first + ' ' +
					data.account_executives_last,
					data.account_executives_email,
					'',
					'',
					'',
					'',
					'',
					'',
					data.end_market_disposition,
					data.size,
					data.install_by,
					data.leave_until,
					data.remove_by,
					data.location_code,
					data.location_name
				);
			}

			this.order = order;

			return order;
		},
	},

};
